
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

.login-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 0;
  color: #fff;
}

.login-img {
  height: auto;
  width: 100%;
}

.login-container img{
  justify-content: center;
  width: 100%;
  background-size: contain; 
  
}

.login-container a{
  text-decoration: none;
}


.login-container.active,
.login-container:focus{
  border: 0;
  background-color: #0bff99;
  color: #9414b2;

}



p {
  font-size: 1rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0 auto;
  margin-top: 1rem;
  padding: .5rem;
  text-align: center;
  font-size: 28px;
  color: #fff;
}

.login-form label{
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0 auto;
  text-align: center;
  font-size: 1rem;
  color: #fff;
}

.login-logo {
  display: flex;
  flex-direction: column;
  width: 10rem;
  height: 5rem;
  margin: 2rem auto;
  margin-top: 1rem;
  padding: .5rem;
}

.error-message{
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  color: #fff;
}

.input{
  padding: .5rem;
  max-width: 400px;
  height: auto;
  margin: .5rem;
  
}
.input-text{
  padding: 1rem;
  width: 14rem;
  max-width: 400px;
  height: .5rem;
  margin: auto;
  text-align: center;
  font-size: 1rem;
  
}
.form button{
  padding: 1rem;
  margin: .5rem;
  max-width: auto;
  height: auto;
  align-self: center;
}

.home-container {
  display: flex;
  flex-direction: row;
  margin: .5rem;
  padding: 0;
  justify-content: center;
  background-color: #000;
  width: auto;
}

.song-img {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 3rem;
}

h1{
  color: #000;
  padding: 1rem;
}

h2{
  color: #fff;
  padding: 1rem;
}

h3{
  color: #000;
  padding: 1rem;
}

h4{
  color: #0bff99;
  padding: 1rem;
}

.navbar{
  border: 2px solid black; 
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 1rem;
  padding: 1rem;
  margin-top: 2rem;
  background-color: #000;
  overflow: hidden;
  position: sticky;
  top: 0;

  
}

.navbar a{
  text-decoration: none;
}

.navbar :hover {
  color: #9414b2;

}



.nav-btn.active,
.nav-btn:focus{
  border: 0;
  background-color: #0bff99;
  color: #9414b2;
  
}

.nav-tag {
  margin: .5rem;
  height: auto;
  width: auto;
  padding: .5rem;
  text-align: center;
  
}

.mood-bar {
  border: 2px solid black; 
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 1rem;
  padding: 1rem;
  margin-top: 2rem;
  background-color: #000;
  overflow: hidden;
  border-radius: 40px;
  position:sticky;
  top: 0;
 
}

.mood-tag {
  margin: .5rem;
  height: auto;
  width: auto;
  text-align: center;
  justify-content: center;
  
}

.header {
  display: flex;
  justify-content: space-evenly;
  background: #000;
  color: white; 
  height: 8rem;
  padding: 1rem;
  overflow: hidden;
  position:sticky;
  top: 0;
  width: 100%;
  z-index: 1;
}


.header img {
  display: flex;
  justify-self: left;
  max-width: 10rem;
  max-height: 5rem;
  border-radius: 20%;
  
}



button {
  position: relative;
  background-color: #ffffff;
  border: rgb(0, 0, 0) solid 1px;
  border-radius: 25px;
  height:auto;
  width: max-content;
  margin: .5rem;
  padding: 5px;
  color: rgb(0, 0, 0);
  font-size: 16px;
  justify-self: center;
  
}

.clock-btn{
  margin: auto;
  padding: 0;
  border:#9414b2 solid 5px;
  height:auto;
  background-color: #0bff99;
}



.header-btn{
  position: relative; 
  border-radius: 5px;
  height: 2rem;
  width: max-content;
  margin: .5rem;
  padding: 2px;
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 600;
  justify-self: center;
}

.header-btn.active,
.header-btn:focus{
  border: 0;
  background-color: #0bff99;
  color: #9414b2;

}

.header-btn :hover {
  color: #9414b2;

}

.card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  padding: 120px 20px 20px 20px;
  margin: 2rem;
  border-radius: 20px;
  background: #fff, opacity .8;
  line-height: 19px;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  overflow: hidden;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem;
  height: fit-content;
  width: 50%;
  background-color: #0bff99 ;
  text-align: center;
  border-radius: 10px;
}
.profile-img{
  border-radius: 50%;
  width: 100%;
  height: auto;
  padding: 1rem;
}

.profile-text{
  padding: 1rem;
}

.user-img {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  border-radius: 10px;
}


.about img{
  margin-top: 0;
  display:grid;
  justify-self: center;
  width: 100%;
  overflow: hidden;
  padding: 0 3rem;
}

.playlists-container {
  display: grid;
  grid-template-rows: auto;
  justify-content: center;
  background-color: #000;

}
.playlist-song-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #9414b2;
  border-radius: 10px;
  margin: 1rem;
  padding: 1rem;
  display: flex;
  justify-items: center;
  width: 25rem;
  text-align: center;
}
.playlist-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  border:#0bff99 solid 1px;
}

.playlist-img{
  display: block;
  width: 90%;
  max-height: 250px;
  padding-left: 2rem;
 
}

.playlist-view ul {
  list-style-type: none;
  padding: 0;
  color: #0bff99;
}

.playlist-view li {
  margin: 5px 0;
  color: #0bff99;
}

.song-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  grid-template-columns: auto;
  align-items: center;
  background-color: #000;
  

}

.songs-container {
  display: grid;
  justify-content: center;
  background-color: #000;
  padding: 1rem;
  
}


.allsongs-img{
  display:block;
  justify-self: center;
  width: 100%;
  overflow: hidden;
  padding: 5rem;
 
}

figure {
  width: 100%;

}

.song-player {
  display: flex;
  justify-items: center;
  width: 18rem;
  text-align: center;
  
}


@media (min-width: 1200px) {


  .login-img {
    height: auto;
    width: 60vw;
  }
  
  .login-container {
    display: grid;
    justify-content: center;
    width: 100%;
    background-size: contain; 
    
  }

  .header {
    display: flex;
    justify-content: center;
    background: #000;
    color: white; 
    height: 8rem;
    padding-left: 4rem;
    padding-right: 4rem;
    overflow: hidden;
    position:sticky;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .header button {
    margin: .4rem;
    
    
  }
  
  .navbar {
  border: 2px solid black; 
  display: flex;
  flex-wrap: nowrap;
  max-width: 50vw;
  width: 100%;
  height: 5rem;
  padding: auto;
  margin: auto;
  background-color: #000;
  overflow: hidden;
  position: sticky;
  top: 0;
}
.mood-bar {
  display: flex;
  align-content: center;
  max-width: 50vw;
  width: 100%;
  height: 5rem;
  padding: 0.5rem;
  position: sticky;
  border-radius: 10px;
}

.mobile {
  display: grid;
  justify-content: center;

}

.about-img{
  margin-top: 0;
  display: grid;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}


.home-container {
  display: flex;
  flex-direction: row;
  margin: .5rem;
  padding-left: 10rem;
  padding-right: 10rem;
  justify-content: center;
  background-color: #000;
  width: auto;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem;
  height: fit-content;
  width: 50%;
  background-color: #0bff99 ;
  text-align: center;
  border-radius: 10px;
}

.song-img {
  display: none;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding-left: 5rem;
  padding-right: 5rem;

}

.songs-container {
  display: grid;
  justify-content: center;
  background-color: #000;
}

.allsongs-img{
  margin-top: 0;
  display: grid;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}
 
}

  


@media(max-width: 600px) {

  .mobile {
    display: flex;
    margin: 2rem;
    overflow: hidden;

}

.header {
  display: grid;
  justify-content: center;
  background: #000;
  color: white; 
  height: auto;
  padding: 1rem;
  overflow: hidden;
  position:sticky;
  top: 0;
  width: 100%;
  z-index: 1;
}
.header-img{
  display: grid;
  justify-content: center;
  overflow: hidden;
  position:sticky;
 padding: 1rem;
}

.header-btn{
 margin: 2rem;
}
  .navbar {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      height: auto;
      padding: 0;
      margin: 0;
  }
  .mood-bar {
      flex-direction: column;
      grid-template-columns: 1fr, 1fr, 1fr;
      height: auto;
      padding: 0.5rem;
      margin-right: 2rem;
      position: sticky;
      border-radius: 10px;
  }

  .home-container {
      flex-direction: column;
    
  }

  .profile {
    display: flex;
    flex-direction: row;
    column-count: 2;
    align-items: center;
    margin: 3rem;
    height: 150px;
    width: auto;
    background-color: #0bff99 ;
    text-align: center;
    border-radius: 10px;
    font-size: small;
  }
  .profile img{
    padding-right: 1rem;
    width: 40vw;
    
  }
  .profile h3{
    display:none;
  }
  
  .profile text{
    display:none;
  }

  .user-img{
    height: auto;
    max-width: 25vw;
  border-radius: 50px;
    
    
  }
  .song-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem;
    height: fit-content;
    grid-template-columns: auto;
    background-color: #000;
    text-align: center;
    font-size: small;
  
  }

  .song-img {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    border-radius: 10px;
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

@media(max-width: 375px) {

.header {
  display: grid;
  justify-content: center;
  background: #000;
  color: white; 
  height: auto;
  padding: 1rem;
  overflow: hidden;
  position:sticky;
  top: 0;
  width: 100%;
  z-index: 1;
}
.header-img{
  display: grid;
  justify-content: center;
  overflow: hidden;
  position:sticky;
 padding: 1rem;
  
}

.header-btn{
  margin: 2rem;
 }

.profile {
  display: flex;
  flex-direction: row;
  column-count: 2;
  align-items: center;
  margin: 3rem;
  height: 150px;
  width: auto;
  background-color: #0bff99 ;
  text-align: center;
  border-radius: 10px;
  font-size: small;
  padding: 2rem;
}

.song-img {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding-left: 5rem;
  padding-right: 5rem;
}
}